import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ContentState } from 'draft-js';
import { ResizableBox } from 'react-resizable';
import PropTypes from 'prop-types';
import { Col, Row, Divider, Button, Tooltip } from 'antd';

import getCroppedImg from './helpers/cropImage';
import ComponentWrapper from '../ComponentWrapper/ComponentWrapper';
import Uploader from 'components/Uploader';
import Loader from 'components/Loader';
import ShadowPicker from 'components/ShadowPicker/ShadowPicker';
import BorderPicker from 'components/BorderPicker/BorderPicker';
import helpers from 'helpers';
import { ImageEditor } from './components';
import {
  DeleteIcon,
  LeftAlignIcon,
  CenterAlignIcon,
  RightAlignIcon,
  HyperLinkIcon,
} from 'components/Icons';

import './ImageComponent.scss';
import 'react-resizable/css/styles.css';

const INFINITY = 30000;

const DEFAULT_CONFIG = {
  opacity: 1,
  editable: true,
  size: { width: 900, height: 2000, ratio: 1 },
  alignment: 'left',
  border: {},
  shadow: {},
};

const ImageComponent = ({ block, blockProps }) => {
  const isRtl = ['hebrew', 'arabic'].includes(blockProps.language);

  const contentState = ContentState.createFromBlockArray([block]);
  const ent = block.getEntityAt(0);
  const entity = contentState.getEntity(ent);
  const isSection = blockProps.type === 'section-image';
  const resizefactor = blockProps.resizefactor || 1;
  const [resizeWidth, setResizeWidth] = useState((blockProps.resizeWidth || 530) / resizefactor);
  const [loadedDimension, setLoadedDimension] = useState(null);
  const imageInputRef = useRef();
  const { data } = entity;
  const imgContent = helpers.formatS3Link(data?.src);
  const [config, setConfig] = useState({
    ...DEFAULT_CONFIG,
    size: { width: resizeWidth, height: INFINITY, ratio: 1 },
    alignment: isRtl ? 'right' : 'left',
    border: data?.config?.border || {},
    shadow: data?.config?.shadow || {},
    opacity: data?.config?.opacity ?? 1,
  });

  const [hyperlink, setHyperlink] = useState(data?.hyperlink || '');
  const [editMode, setEditMode] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [rotation, setRotation] = useState(0);
  const [zoom, setZoom] = useState(1);
  const [activeWidth, setActiveWidth] = useState({
    id: 0,
    width: 1,
    height: 1,
  });
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(imgContent);
  const [shadow, setShadow] = useState(config?.shadow || {});
  const [border, setBorder] = useState(config?.border || {});
  const [isSaving, setIsSaving] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [toolOpen, setToolOpen] = useState(false);

  const [upload] = Uploader();

  const optionList = [
    { id: 0, width: 1, height: 1, label: '1:1' },
    { id: 1, width: 3, height: 4, label: '3:4' },
    { id: 2, width: 4, height: 3, label: '4:3' },
    { id: 3, width: 0, height: 0, label: 'Full' },
  ];

  useEffect(() => {
    setConfig((prev) => {
      // on crop, change ratio and height of image
      const ratio = getRatio(activeWidth.width, activeWidth.height);
      const newConfig = {
        ...prev,
        size: {
          ...prev.size,
          ratio,
          height: prev.size.width / ratio,
        },
      };

      return newConfig;
    });
  }, [activeWidth]);

  useEffect(() => {
    if (data?.config) {
      setConfig((prev) => ({ ...prev, ...JSON.parse(JSON.stringify(data.config)) }));
    }
  }, [data]);

  useEffect(() => {
    if (isSection) {
      setResizeWidth(900);
    } else {
      // NOTE: on blockProps.titleFontPosition change useEffect is not happening
      const { titleFontPosition } = blockProps;
      // on changing title font position change max width
      const _width = ['top', 'top-middle', 'none'].includes(titleFontPosition)
        ? 796
        : titleFontPosition === 'large'
        ? 664
        : 530;
      setResizeWidth(_width / resizefactor);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [blockProps.titleFontPosition, resizefactor]);

  useEffect(() => {
    // on section layout chnage
    if (resizeWidth && !isSection) {
      setConfig((prev) => {
        const newConfig = { ...prev };

        // when column width changes and width of old image
        // is bigger than new column width change old width & height
        if (!newConfig?.size?.width || newConfig.size.width > resizeWidth) {
          if (!newConfig?.size) {
            newConfig.size = {};
          }
          newConfig.size.width = resizeWidth;

          if (newConfig.size?.ratio) {
            newConfig.size.height = newConfig.size.width / newConfig.size.ratio;
          } else {
            let ratio = 1;
            if (prev?.size?.width) {
              ratio = getRatio(prev.size.width, prev.size.height);
            } else if (loadedDimension?.width) {
              ratio = getRatio(loadedDimension.width, loadedDimension.height);
            }
            newConfig.size.height = newConfig.size.width / ratio;
          }
        }

        return newConfig;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeWidth]);

  const handleOpacity = (opacity) => {
    setConfig((prev) => ({ ...prev, opacity }));
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const saveImage = useCallback(
    async (file) => {
      const entityKey = block.getEntityAt(0);
      if (entityKey) {
        const { url } = await upload(file, `props/${blockProps.proposalId}/section`);
        const newData = JSON.parse(JSON.stringify(data));
        newData.src = url;
        newData.config = { ...config };
        contentState.replaceEntityData(entityKey, { ...newData });
        blockProps.update();
        setCroppedImage(url);
        setEditMode(false);
        setIsSaving(false);
      } else {
        setIsSaving(false);
      }
    },
    [block, blockProps, data, upload, contentState, config]
  );

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imgContent, croppedAreaPixels, rotation);
      saveImage(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, rotation, imgContent, saveImage]);

  const handleRemove = () => blockProps?.onRemove(block.getKey());

  const handleEdit = (value = true) => {
    setEditMode(typeof value === 'object' ? true : value);
    if (!value) {
      setConfig((prev) => ({
        ...prev,
        opacity: data?.config?.opacity ?? 1,
      }));
    }
  };

  const handleBlur = () => blockProps.handleEditComponent(false);

  const onSave = () => {
    setIsSaving(true);
    showCroppedImage();
  };

  const saveShadow = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data;
      newData.config = JSON.parse(JSON.stringify({ ...newData.config, shadow: value }));
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const saveBorder = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data;
      newData.config = JSON.parse(JSON.stringify({ ...newData.config, border: value }));
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const onReplace = () => {
    imageInputRef.current.click();
  };

  const onImageSelect = async (event) => {
    event.preventDefault();
    const { files } = event.target;
    if (files && !files.length) return;
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      const { url } = await blockProps.upload(files[0], `props/${blockProps.proposalId}/section`);
      const newData = JSON.parse(JSON.stringify(data));
      newData.src = url;
      delete newData?.config?.size;
      contentState.replaceEntityData(entityKey, {
        ...newData,
      });
      setHideImage(true);
      blockProps.update();
      delete config?.size;
      setConfig((prev) => ({ ...prev, ...config }));
      setCroppedImage(url);
      setHideImage(false);
    } else {
      setIsSaving(false);
    }
  };

  const boxShadow = `rgba(${shadow?.hueColor?.rgb?.r || 0},${shadow?.hueColor?.rgb?.g || 0},${
    shadow?.hueColor?.rgb?.b || 0
  },${shadow?.alphaColor?.rgb?.a || 1}) ${shadow.xOffset || 0}px ${shadow.yOffset || 0}px ${
    shadow.blur || 0
  }px ${shadow.spread || 0}px`;

  const borderStyle =
    `${border.borderWidth || 0}px solid ${border.borderColor?.hex || 'black'}` || 'none';
  const borderRadiusStyle = `${border.borderRadius}px` || '0px';

  const saveResize = (value, _config) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && value) {
      const newData = data || {};
      if (_config) {
        newData.config = _config;
      } else {
        if (newData.config === undefined) {
          newData.config = {};
        }
        newData.config.size = JSON.parse(
          JSON.stringify({
            ...config.size,
            width: value?.width,
            height: value?.height,
            ratio: getRatio(value?.width, value?.height),
          })
        );
      }
      contentState.replaceEntityData(entityKey, { ...newData });
      blockProps.update(true);
    }
  };

  const onResize = (e, resizeData) => {
    e.preventDefault();

    setConfig((prev) => {
      return {
        ...prev,
        size: {
          ...prev.size,
          width: resizeData.size.width || prev.size?.width,
          height: resizeData.size.height || prev.size?.height,
          // maxHeight: height,
        },
      };
    });
  };

  const onResizeStart = (e) => {
    e.preventDefault();
    setEditMode(false);
  };

  const onResizeStop = (e, resizeData) => {
    e.preventDefault();
    saveResize(resizeData.size, config);
  };

  const handleImageAlignment = (alignment) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey && alignment) {
      setConfig((prev) => {
        const newConfig = { ...prev, alignment: alignment };
        const newData = data;
        newData.config = newConfig;
        contentState.replaceEntityData(entityKey, { ...newData });
      });
      blockProps.update(true);
    }
  };

  const getRatio = (width, height) => {
    return parseFloat((width / height).toFixed(2));
  };

  const handleOnLoad = (e) => {
    const { offsetHeight: height, offsetWidth: width } = e.target;
    setLoadedDimension({ height, width });

    if (!data?.config?.size?.width || !data?.config?.size?.height) {
      // w x h , 100 x 50 = ratio 2
      // w = 40
      // h = ? W/ratio

      const _config = {
        ...config,
        editable: true,
        size: {
          width,
          height,
          maxHeight: height,
          ratio: getRatio(width, height), // preserve the original aspect ratio
        },
        alignment: data?.config?.alignment || (isRtl ? 'right' : 'left'),
      };

      setConfig(_config);
      saveResize(_config.size, _config);
      optionList[3] = { ...optionList[3], width, height };
    }
  };

  const onEdit = typeof imgContent === 'string' && imgContent.endsWith('.gif') ? null : handleEdit;

  const handleDeleteVisibleChange = (visible) => {
    setToolOpen(false);
    handleBlur();
  };

  const onHyperLinkClick = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const { dispatch, uniqueKey } = blockProps;
    let shouldOpen = true;

    const element = document.getElementById(`img-hyperlink-${uniqueKey}`);
    if (element) {
      if (element.style.display === 'none') {
        // if display is none, then close it and open fresh
        dispatch({
          type: 'setDisplayHyperlinkInput',
          displayHyperlinkInput: false,
        });
      } else {
        // if display is block, then close it
        shouldOpen = false;
      }
    }

    // hide all hyperlink modal
    const elements = document.querySelectorAll('.hyperlink-content');
    elements.forEach((el) => {
      el.style.display = 'none';
    });

    const actionButtonBound = event.target
      .closest('.rich-editor-components-action-buttons')
      .getBoundingClientRect();
    const sectionBound = event.target.closest('.simple-section-content').getBoundingClientRect();

    let y = actionButtonBound.top - sectionBound.top + 25;
    if (actionButtonBound.bottom + 80 > window.innerHeight) {
      y -= 120;
    }

    if (shouldOpen) {
      setTimeout(() => {
        dispatch({
          type: 'setDisplayHyperlinkInput',
          displayHyperlinkInput: {
            position: {
              display: 'block',
              top: `${y + Math.random()}px`,
            },
            link: hyperlink,
            onSubmit: handleHyperLinkSave,
          },
        });
      }, 20);
    } else {
      dispatch({
        type: 'setDisplayHyperlinkInput',
        displayHyperlinkInput: false,
      });
    }
  };

  const handleHyperLinkSave = (value) => {
    const entityKey = block.getEntityAt(0);
    if (entityKey) {
      const newData = data;
      newData.hyperlink = value;
      contentState.replaceEntityData(entityKey, { ...newData });
      setHyperlink(value);
      blockProps.update(true);
    }
  };

  // find width
  let width = isSection
    ? 900
    : (config?.size?.width && window?.screen?.width > 480) ||
      (window?.screen?.width < 480 && config?.size?.width < 360)
    ? config?.size?.width || resizeWidth
    : INFINITY;
  width = Math.min(window.innerWidth - 62, resizeWidth, width);

  // find ratio
  let ratio = isSection
    ? 1
    : config?.size?.ratio
    ? config.size.ratio
    : getRatio(config?.size?.width || resizeWidth, config?.size?.height || 400);

  // find height
  let height = isSection ? config?.size?.height || INFINITY : width / ratio;

  const maxWidth = resizeWidth;
  const maxHeight = isSection ? INFINITY : maxWidth / ratio;

  return (
    <>
      <ComponentWrapper
        isSection={isSection}
        sectionName={blockProps.sectionName}
        setDraggingElement={blockProps.setDraggingElement}
        showDrag={isSection ? false : true}
        showActionButtons={true}
        showImageActions={true}
        config={config}
        hyperlink={hyperlink}
        border={border}
        showDelete={false}
        isDraggable={true}
        blockKey={block.getKey()}
        remove={handleRemove}
        onEdit={null}
        onBlur={handleBlur}
        componentType="Image"
        handleEditComponent={blockProps.handleEditComponent}
        isSaving={isSaving}
        onHyperLinkSave={handleHyperLinkSave}
        setDropDisabled={blockProps.setDropDisabled}
        isResizable
        componentWrapperStyle={editMode ? {} : { width: width, height: height + 35 }}
        toolbarClassName="image-toolbar"
        toolbar={
          !editMode && (
            <>
              <Col>
                <span onClick={onEdit}>Edit</span>
                <span onClick={onReplace}>Replace</span>

                <span className={isSaving ? 'rich-editor-components-action-disabled' : ''}>
                  <Tooltip title="Hyperlink" onClick={onHyperLinkClick}>
                    <HyperLinkIcon />
                  </Tooltip>
                </span>
                <span className="component-tool">
                  <span
                    onClick={() => setToolOpen((prev) => (prev === 'delete' ? false : 'delete'))}>
                    <span className={isSaving ? 'rich-editor-components-action-disabled' : ''}>
                      <Tooltip title="Delete">
                        <DeleteIcon className="delete-icon" />
                      </Tooltip>
                    </span>
                  </span>
                  {toolOpen === 'delete' && (
                    <div className="tool-wrapper delete-picker-wrapper Prosprich-editor-components-popover">
                      <div className="ant-popover-inner">
                        <div className="ant-popover-inner-content">
                          <Col className="table-delete-popover">
                            <h3 className="ant-popover-title">Delete image?</h3>
                            <Divider />
                            <Row>
                              <Button
                                size="middle"
                                type="button"
                                className="Prosprich-editor-components-popover-confirm"
                                onClick={handleRemove}>
                                Delete
                              </Button>
                              <Button
                                size="middle"
                                type="button"
                                className="Prosprich-editor-components-popover-cancel"
                                onClick={() => handleDeleteVisibleChange(false)}>
                                Cancel
                              </Button>
                            </Row>
                          </Col>
                        </div>
                      </div>
                    </div>
                  )}
                </span>
              </Col>

              <Col>
                <span className="component-tool">
                  <span
                    onClick={() => setToolOpen((prev) => (prev === 'shadow' ? false : 'shadow'))}>
                    Shadow
                  </span>
                  {toolOpen === 'shadow' && (
                    <div className="tool-wrapper shadow-picker-wrapper Prosprich-editor-components-popover">
                      <div className="ant-popover-inner">
                        <div className="ant-popover-inner-content">
                          <ShadowPicker
                            shadow={shadow}
                            onChange={(value) => setShadow(value)}
                            save={(value) => {
                              setToolOpen(false);
                              saveShadow(value);
                            }}
                            cancel={() => {
                              setShadow(config?.shadow || {});
                              setToolOpen(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </span>
                <span className="component-tool">
                  <span
                    onClick={() => setToolOpen((prev) => (prev === 'border' ? false : 'border'))}>
                    Border
                  </span>
                  {toolOpen === 'border' && (
                    <div className="tool-wrapper border-picker-wrapper Prosprich-editor-components-popover">
                      <div className="ant-popover-inner">
                        <div className="ant-popover-inner-content">
                          <BorderPicker
                            border={border}
                            onChange={(value) => setBorder(value)}
                            save={(value) => {
                              saveBorder(value);
                              setToolOpen(false);
                            }}
                            cancel={() => {
                              setBorder(config?.border || {});
                              setToolOpen(false);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </span>

                <Tooltip title="Left Align">
                  <LeftAlignIcon
                    className={config?.alignment === 'left' ? 'anticon-active' : ''}
                    onClick={() => handleImageAlignment('left')}
                  />
                </Tooltip>
                <Tooltip title="Center Align">
                  <CenterAlignIcon
                    className={config?.alignment === 'center' ? 'anticon-active' : ''}
                    onClick={() => handleImageAlignment('center')}
                  />
                </Tooltip>
                <Tooltip title="Right Align">
                  <RightAlignIcon
                    className={config?.alignment === 'right' ? 'anticon-active' : ''}
                    onClick={() => handleImageAlignment('right')}
                  />
                </Tooltip>
              </Col>
            </>
          )
        }>
        {editMode ? (
          <div className="image-component-container img-249">
            {isSaving && <Loader overlay />}
            <ImageEditor
              optionList={optionList}
              imgContent={imgContent}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              opacity={config.opacity}
              activeWidth={activeWidth}
              setCrop={setCrop}
              setRotation={setRotation}
              onCropComplete={onCropComplete}
              setZoom={setZoom}
              setOpacity={handleOpacity}
              setActiveWidth={setActiveWidth}
            />

            <div className="edit-image-action-btns">
              <Button
                size="middle"
                type="button"
                className={`small-btn secondary-btn ${
                  isSaving ? 'rich-editor-components-action-disabled' : ''
                }`}
                onClick={onSave}>
                Save
              </Button>
              <Button
                size="middle"
                type="button"
                className={`small-btn grey-btn ${
                  isSaving ? 'rich-editor-components-action-disabled' : ''
                }`}
                onClick={() => handleEdit(false)}>
                Cancel
              </Button>
            </div>
          </div>
        ) : !imgContent ? (
          <div className="gallery-container" onClick={blockProps.editComponent}>
            <div className="gallery-image-container">
              <div className="gallery-uploader">
                <div>Upload Image</div>
              </div>
            </div>
          </div>
        ) : (
          <ResizableBox
            draggableOpts={{ offsetParent: document.body, grid: [2, 2] }}
            lockAspectRatio={isSection ? false : true}
            axis={'both'}
            width={width}
            height={height}
            onResize={onResize}
            onResizeStart={onResizeStart}
            onResizeStop={onResizeStop}
            resizeHandles={isSection ? ['s'] : isRtl ? ['sw'] : ['se']}
            minConstraints={
              config?.size?.width > config?.size?.height
                ? [(config?.size?.width / config?.size?.height) * 50, 50]
                : [50, (config?.size?.height / config?.size?.width) * 50]
            }
            maxConstraints={[maxWidth, isSection ? INFINITY : maxHeight]}
            className={`image-component-container img-311 ${
              height === INFINITY ? 'infinity-height' : ''
            }`}>
            {!hideImage && (
              <img
                src={croppedImage}
                onLoad={handleOnLoad}
                alt="cropped"
                style={{
                  width: isSection ? '100%' : `calc(100% - 2px)`,
                  aspectRatio:
                    (isSection && height === INFINITY) ||
                    (!isSection && config?.size?.height === INFINITY)
                      ? 'inherit'
                      : isSection
                      ? `${width} / ${height - 2}`
                      : `${config?.size?.width || 900} / ${config?.size?.height - 1}`,
                  opacity: config.opacity,
                  boxShadow: boxShadow,
                  border: borderStyle,
                  borderRadius: borderRadiusStyle,
                }}
              />
            )}
          </ResizableBox>
        )}
      </ComponentWrapper>
      <input
        type="file"
        ref={imageInputRef}
        style={{ display: 'none' }}
        onChange={onImageSelect}
        accept="image/*"
      />
    </>
  );
};

ImageComponent.propTypes = {
  block: PropTypes.instanceOf(Object).isRequired,
  blockProps: PropTypes.instanceOf(Object).isRequired,
};

export default ImageComponent;

/* put on image wrap when enabling width editing: style={{width: data.width || '30%' }} */
