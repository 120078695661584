import React from 'react';
import PropTypes from 'prop-types';
import { Form, Menu, Checkbox, Input, Row, Col, Select, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';

import Languages from 'constants/languages';
import DateFormats from 'constants/date-format';
import { CURRENCY_FORMATS } from 'constants/currency';
import Sparkle from 'components/Icons/Sparkle';

const ProjectInfo = ({ user, projectsType, clickedCheckbox, setClickedCheckbox }) => {
  const handleCheckboxClick = (index) => {
    clickedCheckbox[index] = !clickedCheckbox[index];
    setClickedCheckbox([...clickedCheckbox]);
  };

  const filteredProjects = projectsType.filter((_, index) => clickedCheckbox[index]);
  const menuItems = (
    <Menu>
      {projectsType.map((el, index) => (
        <Menu.Item key={el.key} className="menu-item border-bottom">
          <div
            className="menu-item_"
            onClick={(e) => {
              e.stopPropagation();
              handleCheckboxClick(index);
            }}>
            <div className="label">
              {el.icon}
              {el.label}
            </div>
            <Checkbox
              className="item-text"
              checked={clickedCheckbox[index]}
              onChange={() => handleCheckboxClick(index)}
              onClick={(e) => {
                handleCheckboxClick(index);
              }}></Checkbox>
          </div>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <>
      <Form.Item label="Proposal Language" name="proposalLanguage" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {Languages.map((language) => (
            <Select.Option key={language.value} value={language.value}>
              {language.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Date Format" name="dateFormat" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {DateFormats.map((format) => (
            <Select.Option key={format.value} value={format.value}>
              {format.text}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="Default Currency" name="currency" labelCol={false}>
        <Select suffixIcon={<CaretDownOutlined color="red" />}>
          {CURRENCY_FORMATS.map((currency) => (
            <Select.Option key={currency.code} value={currency.symbol}>
              {currency.symbol} ({currency.code})
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item label="What type of projects do you offer?" name="ptype" labelCol={false}>
        <Dropdown
          overlayClassName="multi-select dashboard-multi-select-menu onboard-form"
          overlay={menuItems}
          trigger={['click']}>
          <Input
            className="select-trigger"
            suffix={<CaretDownOutlined color="red" />}
            value={filteredProjects.map((project) => project.label).join(', ')}
            readOnly
          />
        </Dropdown>
      </Form.Item>
      {clickedCheckbox[clickedCheckbox.length - 1] ? (
        <Form.Item
          label="Other project type?"
          name="otherptype"
          rules={[{ max: 50, message: 'Max length exceeded 50 characters' }]}
          labelCol={false}>
          <Input value={user ? user.otherptype : ''} placeholder="Write short description" />
        </Form.Item>
      ) : null}

      <Row className="helper-text-wrapper-step-3">
        <Col span={1} className="helper-text-step-3 hand-icon">
          <Sparkle />
        </Col>
        <Col span={22} className="helper-text-step-3 text">
          This will help us to match you the most relevant content for your proposals
        </Col>
      </Row>
    </>
  );
};

ProjectInfo.propTypes = {
  user: PropTypes.instanceOf(Object).isRequired,
  projectsType: PropTypes.instanceOf(Array).isRequired,
  clickedCheckbox: PropTypes.instanceOf(Array).isRequired,
  setClickedCheckbox: PropTypes.func.isRequired,
};

export default ProjectInfo;
